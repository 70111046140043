import Bandaid from './Bandaid';
import BackedByNHS from './BackedByNHS';
import BritishIsles from './BritishIsles';
import { Calendar } from './Calendar';
import Cross from './Cross';
import DeliveryBox from './DeliveryBox';
import Doctors from './Doctors';
import DownArrow from './DownArrow';
import Empowerment from './Empowerment';
import Flask from './Flask';
import Hamburger from './Hamburger';
import Handshake from './Handshake';
import Info from './Info';
import Instagram from './InstagramLogo';
import Lifebuoy from './Lifebuoy';
import MagicWand from './MagicWand';
import Medal from './Medal';
import MetaLogo from './MetaLogo';
import Microscope from './Microscope';
import Mountains from './Mountains';
import Pin from './Pin';
import Play from './Play';
import Plus from './Plus';
import RightArrow from './RightArrow';
import LeftArrow from './LeftArrow';
import Scale from './Scale';
import Search from './Search';
import Star from './Star';
import Stopwatch from './Stopwatch';
import SugarCubes from './SugarCubes';
import TickInCircle from './TickInCircle';
import TickInCircleWithFill from './TickInCircleWithFill';
import Tick from './Tick';
import Twitter from './TwitterLogo';
import Warning from './Warning';
import WeightLoss from './WeightLoss';
import World from './World';
import { InfoCircle } from './InfoCircle';

export const smallIcons = {
  cross: Cross,
  downArrow: DownArrow,
  hamburger: Hamburger,
  info: Info,
  infoCircle: InfoCircle,
  instagram: Instagram,
  leftArrow: LeftArrow,
  meta: MetaLogo,
  pin: Pin,
  play: Play,
  plus: Plus,
  rightArrow: RightArrow,
  scale: Scale,
  search: Search,
  star: Star,
  stopwatch: Stopwatch,
  tick: Tick,
  tickInCircle: TickInCircle,
  tickInCircleWithFill: TickInCircleWithFill,
  twitter: Twitter,
  warning: Warning,
  world: World,
};

export const largeIcons = {
  backedByNhs: BackedByNHS, // Do not reuse this icon due to the NHS logo guidelines, we will be replacing existing usage with a new icon
  bandaid: Bandaid,
  britishIsles: BritishIsles,
  calendar: Calendar,
  deliveryBox: DeliveryBox,
  doctors: Doctors,
  empowerment: Empowerment,
  flask: Flask,
  handshake: Handshake,
  lifebuoy: Lifebuoy,
  magicWand: MagicWand,
  medal: Medal,
  microscope: Microscope,
  mountains: Mountains,
  sugarCubes: SugarCubes,
  weightLoss: WeightLoss,
};

const icons = {
  ...smallIcons,
  ...largeIcons,
};

export type IconName = keyof typeof icons;

type Props = { name: IconName };

const Icon = ({ name }: Props): JSX.Element => {
  const ChosenIcon = icons[name];
  return <ChosenIcon />;
};

export default Icon;
